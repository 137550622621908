@import '../utilities/customTheme';
@import '../utilities/variable_classes';
@import '../utilities/mixin';
@import '../utilities/variable';


.CE_INTEGRATION_APP {
    .company-field-display.ce_integration_app {
        h3 {
            @include h3();
        }
        article {
            @include body-medium-condensed(); 
            p {
                em {
                    span {
                        @include body-medium-condensed();
                    }
                }
            }
            h3 {
                @include h3();
            }
            
            table {
                td {
                    h4 {
                        padding-block-start: $spacing03;
                        @include h4()
                    }
                    &:nth-child(n + 2) {
                        vertical-align: text-top;
                    }
                    p {
                        @include body-medium-condensed();
                        padding-inline: $spacing02
                    }
                    ul {
                        @include body-medium-condensed();
                        li {
                            padding-inline: $spacing03;
                        }
                    }
                    h1 {
                        span {
                            @include h3();
                        }
                    }
                }
            }
        }
    }
}