.jvectormap-container {
    height:100%;
    width:100%;
}

.irc_app {

    .dropdown-menu {
        max-height: 400px;
        overflow-y: scroll;
    }

}

.irc-country-cards {
    display: flex;
    padding: 0px;
    flex-wrap: wrap;
}

.country-card {
    cursor: pointer;
    width: 100%;
    height: 405px;
    max-width: 306px;
    border-radius: $border-radius-medium;
    @include xl {
        max-width: 356px !important;
    }

    &:hover {
        box-shadow: $boxshadow-depth-16;
    }
    
    .card-img img{
        width: 100%;
        height: 200px;
        max-width: 306px;
        max-height: 200px;
        border-top-left-radius: $border-radius-medium;
        border-top-right-radius: $border-radius-medium;
        @include xl {
            max-width: 356px !important;
        }
        object-fit: cover;
    }

    .country-title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .region-badge {
        background-color: $linksbridge-brand-secondary-500 !important;
        border: $linksbridge-brand-secondary-500 !important;
        padding: $spacing02 $spacing04;
        justify-content: center;
        align-items: center;
        border-radius: $spacing08;
    }
    
    .data-row {
        display: flex;
        flex-direction: column;
        justify-content: left;
        align-items: left;
    }
}

