@import '../utilities/variable';

.META {
  .tenant-top-nav {
    .brand-col {
      background-color: $METAprimary;
    }

    .search-col {
      background-color: $METAprimary;
    }

    .signin-col {
      background-color: $METAprimary;

      .btn-primary {
        background-color: $METAprimary !important;
      }
    }
  }

  .tenant-info {
    color: $METAprimary !important;

    h5 {
      color: $METAprimary !important;
    }

    p {
      color: $METAprimary !important;
      border-bottom: 1px solid $METAprimary !important;
    }
  }

  .setting-feedback-login {

    // tenant selector styles
    .tenant-select {
      .css-yk16xz-control {
        background-color: $blackwhite50 !important;
        border: 1px solid $METAprimary !important;

        .css-1uccc91-singleValue {
          color: $METAprimary !important;
        }

        .css-1okebmr-indicatorSeparator {
          border: 1px solid $METAprimary !important;
        }

        svg {
          color: $METAprimary !important;
        }

        .react-select__menu-list {
          .react-select__option {

       
              background-color: $METAprimary !important;
              color: $purewhite;
      
            &:hover {
              background-color: $METAtertiary;
            }
          }
        }
      }
    }

    .support {
      color: $METAprimary !important;

      .feedback-link {
        color: $METAprimary !important;
        &:hover {
          color: $purewhite !important;
        }
      }

      &:hover {
        background-color: $METAtertiary !important;
        color: $purewhite !important;
      }
    }

    button {
      color: $METAprimary !important;

      &:hover {
        background-color: $METAtertiary !important;
        color: $purewhite !important;
      }
    }
  }

  //SIDE NAV----------------------------
  .side-workspace-nav {
    .nav-container {
      .dropdown {
        button {
          background-color: $METAprimary !important;
          border: solid 1px $METAprimary;
        }
      }
      button {
        color: $text-secondary;

        svg {
          color: $purewhite;
        }
      }

      .expand-icon {
        color: $text-secondary !important;
      }
      .sidebar-expand {
        &:active {
          color: $text-secondary !important;
        }
      }

      .sidenav-link {
        color: $text-secondary;

        &:hover {
          background-color: $METAtertiary;
          color: $purewhite;
        }
      }

      .app-link-list {
        .active-tenant {
          background-color: $METAprimary;
          color: $purewhite;
        }
      }

      .support-request {
        border-top: solid $text-secondary 1px;
        .support-row {
          color: $text-secondary;

          &:hover {
            background-color: $METAtertiary;
            color: $purewhite;
          }
        }
      }
    }
  }

  .top-workspace-nav {
    .active-tenant {
      background-color: $METAprimary !important;
    }

    .sidenav-link {
      color: $METAprimary !important;

      &:hover {
        background-color: $METAtertiary !important;
        color: $purewhite !important;
      }
    }

    .support-request {
      .support-link {
        color: $METAprimary;

        &:hover {
          background-color: $METAtertiary;
        }
      }
    }

    .close-menu-row {
      .close-menu {
        color: $METAprimary;
      }

      button {
        color: $METAprimary;
      }

      svg {
        color: $METAprimary !important;
      }
    }
  }

  .story-teaser {
    h5 {
      color: $METAsecondary !important;
    }
  }

  .preview-link {
    color: $METAsecondary;
  }

  .page-item {
    .page-link {
      color: $METAsecondary;
    }
  }

  .page-item.active {
    .page-link {
      background-color: $METAsecondary;
      border: none;
      color: $purewhite;
    }
  }

  span.report-num {
    color: $METAsecondary !important;
  }

  .clear-filter-btn {
    color: $METAprimary !important;
  }

  .tertiary-button {
    background-color: $METAsecondary !important;
    color: $purewhite;
    border: 1px solid $METAsecondary;
    &:hover {
      background-color: $METAsecondary;
      color: $purewhite;
      border: 1px solid $METAsecondary;
    }
  }
  
  .secondary-link {
    color: $METAsecondary !important;
    background-color: $purewhite;
    border: 1px solid $purewhite;
    &:hover {
      border: 1px solid $METAsecondary;
      background-color: $purewhite;
      color: $METAsecondary !important;
      text-decoration: none !important;
    }
  }
  
  .secondary-link-plain {
    color: $METAsecondary !important;
  }

  .add-new-report {
    &:hover {
      background-color: $METAsecondary;
    }
  }

  .button-cancel {
    color: $METAprimary !important;
  }
    
  input[type="checkbox"] {
    accent-color: $METAsecondary !important;
  }
}

// APP Custom styles fro META

.META {
  //removes gap at top pyramid visuals within META app
  div.display-area {
    top: 0 !important;
  }

  //hides settings link on the sidenav bar
  .setting-link {
    display: none;
  }
}