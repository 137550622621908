@charset 'utf-8';
@import '../utilities/variable';
@import '../utilities/mixin';

.top-nav-fixed {
  min-width: 100%;
  top: 0 !important;
  left: 0 !important;
  z-index: 1;
  .nav-margin {
    top: 0 !important;
    left: 0 !important;
    z-index: 2;
  
    .tenant-top-nav {
      min-width: 100%;
      margin-block-end: 0;
  
      // Search input styling --------------------------------------
      .search-col {
        display: flex;
        justify-content: center;
        background-color: $blue500;
        padding-inline-start: 4px;
  
        .nav-app-item {
          padding-block-start: $spacing01;
          width: 100%;
  
          .tenant-app-link {
            width: 100%;
            padding-left: 0;
            padding-right: 0;
            display: flex;
            align-items: center;
  
            .input-group {
              border-radius: 4px;
              max-width: 300px;
              .input-group-text {
                margin-left: 0 !important;
              }
  
              span {
                margin-left: 20px;
                background-color: $blackwhite50 !important;
                border: none;
                padding: 15px 12px 15px 24px;
  
                svg {
                  color: $blackwhite600;
                }
              }
  
              input {
                background-color: $blackwhite50 !important;
                border: none;
                @include bodySmall();
                color: $blackwhite600;
                padding-left: 0;
                margin-right: 20px;
                box-shadow: none; 
                border-style: none;
              }
            }
          }
        }
        .nav-app-item.show {
          margin-inline-start: 298px;
        }
        .nav-app-item.hide {
          margin-inline-start: 118px;
        }
      }
  
      // Userblock and tenant switcher ------------------------------
      .add-margin-top {
        .text-right {
          margin-top: 8px
        }
      }
  
      .signin-col {
        background-color: $blue500;
        padding: 20px 0 16px 0;
        color: $blackwhite50;
  
        .text-right {
          justify-content: right;
          align-items: end;
  
          .user-block {
            .btn-primary {
              @include buttonLarge();
              padding: 8px 0;
              color: $blackwhite50;
              margin-right: 24px;
              background-color: $blue500;
              border: none;
              box-shadow: none;
  
              svg {
                margin-left: 16px;
              }
            }
          }
        }
  
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

// Styles for sidenav---------------------------------SIDENAV--------------------------------------SIDENAV-------------------
.side-workspace-nav {
  z-index: 2;
  position: fixed;
  top: 0;
  bottom: 0;
  margin-inline-start: $spacing04;
  height: 100% !important;
  background-color: $surface-secondary;
  border-right: 1px solid var(--borders-border-secondary, #9395A0);
  .nav-container {
    background-color: $surface-secondary;
    padding: $spacing04;
    height: 100%;

    .workspace-selector {
      color: $purewhite !important;
      padding: $spacing02 0 $spacing02 $spacing02 !important;
      width: 100%;
      align-self: center !important;
      margin-block-end: $spacing06;
      @include h6();
      img {
        height: 40px;
        max-width: 60px px;
        padding: $spacing02;
        border-radius: $spacing01;
        margin-inline-end: $spacing03 !important;
      }
      svg {
        color: $purewhite !important;
        margin-block-start: 12px !important;
      }

      &::after {
        display: none;
      }
    }

    .wks-dd-menu {
      max-width: 224px;
      max-height: 50vh;
      padding-block-start: $spacing02;
      border-radius: 2px;
      background-color: $surface-secondary;
      overflow-y: scroll;
      .col-sm-8 {
        display: flex;
        align-items: center;
        height: 40px;
        @include bodySmall();
      }
    }
    .sidebar-expand {
      background-color: $surface-secondary;
      padding: $spacing03 !important;
      color: $blue500;
      @include bodySmall();
      border: none;
      box-shadow: none;
      margin-block-end: $spacing03;
      &:active {
        background-color: $surface-secondary;
        color: $blue500;
      }

      svg {
        color: $blue500;
      }
    }

    .expand-icon {
      color: $blue500 !important;

      &:hover {
        cursor: pointer;
      }
    }

    .sidenav-link {
      color: $blue500;
      @include bodySmall();
      padding: 0 2px;
      margin-bottom: 8px;

      .col-md-2 {
        display: flex;
        align-items: center;
      }

      .hideAppName {
        padding: 12px 14px;
      }

      &:hover {
        color: $purewhite;
        background-color: $blue300;
        cursor: pointer;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    .app-link-list {
      margin-left: 20px;

      .active-tenant {
        background-color: $blue500;
        color: $purewhite;

        .col-md-2 {
          border-left: solid 2px $purewhite;
        }
      }
    }

    .support-request {
      margin: 8px 4px 0 8px;
      border-top: solid $blue500 1px;
      max-width: 96%;
      padding: 0;
      .support-row {
        padding: 12px 16px;
        color: $blue500;
        margin-block-start: 0 !important;
        margin-inline-start: 4px;
        @include bodySmall();

        .col-md-2 {
          padding-left: 0;
        }
        .col-md-10 {
          padding-left: 0;
        }

        &:hover {
          cursor: pointer;
          background-color: $blue300;
          color: $purewhite !important;
          max-width: 100%;
        }

      }
    }
    .setting-link {
      border-top: none !important;
    }
  }
}

// Styles for side nav when app names are displaying --------------------------------SIDENAV EXPANDED----------------------------
.side-workspace-nav.show {
  padding-inline: 0;
  max-width: 250px;
  .nav-container {

    .app-link-list {
      margin-left: 0 !important;
      .sidenav-link {
        margin-left: 8px;
        max-width: 100%;
        margin-left: 0;
      }
    }

    .support-row {
      margin-top: 8px;
      margin-inline-end: $spacing04;
    }
  }

  button {
    text-align: left;
    padding: 12px 8px 12px 8px !important;
    background-color: $blue500;
    box-shadow: none;
    margin-bottom: 6px;


    svg {
      margin:4px 0 !important;
      float: right;
    }
  }

  .expand-icon {
    color: $blackwhite50;
    margin-top: 8px;
  }

  .hideAppName {
    button {
      @include bodyMedium;
      background-color: $blue400;
      color: $blackwhite50;
      border: none;
      padding: 8px 0;
      margin-left: 20px;
    }
  }

  .minimize {
    padding-right: 0;
    padding-left: 28px;
    margin-bottom: 100px;
  }
}

// Styles for side nav when app names are collapsed
.side-workspace-nav.hide {
  padding-inline: 0;
  max-width: 73px;
  height: 100%;

  .nav-container {
    border: 1px solid $purewhite;
    width: 72px;

  }

  button {
    text-align: left;
    background-color: $surface-secondary;
    border: none;
    padding-right: 0;
    box-shadow: none;
    padding-top: 12px;
    padding-bottom: 12px;
    margin-top: 4px;
    margin-bottom: 12px;
    &:active {
      background-color: $surface-secondary;
    }

    svg {
      margin-top: 0 !important;
      color: $blackwhite50;
      margin-bottom: 8px;
      margin-left: 0;
    }
  }

  .dropdown {
    button {
      background-color: $blue500;
      img {
        max-width: 30px !important;
        max-height: 30px;
      }
    }
    .dropdown-menu {
      --bs-dropdown-min-width: 3rem ;
      .dropdown-item {
        padding-inline: 8px;
      }
      .col-sm-4 {
        img {
           max-width: 30px;
           max-height: 30px;
        }
      }
      .col-sm-8 {
        display: none;
      }
      .collapse-workspace {
        .col {
          svg {
            margin-inline-start: 6px;
          }
        }
        .hide {
          display: none;
        }
      }
    }
  }
  .app-link-list {
    margin-left: 0;
    padding-left: 0;
  }

  .sidenav-link {
    max-width: 50px;
    margin-left: $spacing03;
    .row {
      padding-left: 16px;
    }
    .col-md-12 {
      display: flex;
      min-height: $spacing08;
      svg {
        align-items: center;
        margin: auto;
      }
    }
  }

  .hideAppName {
    display: none;
  }

  .support-request {
    padding-left: 0;
    padding-top: 8px;
    max-width: 50px;
    .support-row {
      margin-left: 0;

      .col-md-2 {
        padding-left: 0;
      }
    }
  }
}

// Styles for signin modal -------------------------------------------
.signin-modal.show {
  z-index: 1;
  border: none;
  border-radius: 0% !important;
  padding: 12px;
  margin-top: 18px !important;
  margin-left: 6px !important;
  max-height: 288px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 3px 7px rgba(0, 0, 0, 0.13);
  background-color: $blackwhite50;

  // tenant name and email styles
  .tenant-info {
    h5 {
      color: $blue500;
      margin-top: 12px;
      margin-bottom: 8px;
      @include bodyLarge();
      padding: 8px 0;
    }

    p {
      color: $text-primary;
      @include subtitle1();
      padding-bottom: 12px;
      border-bottom: 1px solid $text-primary;
      margin-bottom: 12px;
    }
  }

  .setting-feedback-login {
    @include bodyMedium();

    a {
      padding-block: 10px !important;

    }
    .support-request {
      padding-block: 10px !important;

    }
  }

  button {
    @include bodyMedium();
    padding-inline-start: 0 !important;
    padding-block: 10px !important;
    border: none;
    background-color: $blackwhite50;
    color: $blue500;
    min-width: 100%;
    text-align: left;

    &:hover {
      background-color: $blackwhite100 !important;
      border-radius: 4px;
    }
  }
}



// Styles for sidenav viewports md, sm, xs below media max-width: 991px (sidenav moves to top of screen)
.top-workspace-nav {
  margin-bottom: 10px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 3px 7px rgba(0, 0, 0, 0.13);
  background-color: $purewhite;

  .nav-content {
    max-width: 300px;
    min-width: 208px;

    .app-link-list {
      margin-left: 32px;

      .active-tenant {
        border-left: 2px solid $blackwhite50;
        background-color: $blue600;
        color: $purewhite !important;
      }

      .sidenav-link {
        margin-top: 8px;
        color: $blue500;
        padding: 8px 20px !important;
        cursor: pointer;

        .hideAppName {
          @include bodySmall();
          padding: 8px 0 8px 8px;
        }

        &:hover {
          background-color: $blue600;
          color: $purewhite;
        }
      }
    }
    .nav-link {
      padding-inline-start: 12px;
    }
    .support-request {
      text-decoration: none !important;

      .support-link {
        margin-block-start: 4px;
        margin-left: 20px !important;
        color: $blue500;
        padding: 8px 10px 8px 30px;
        margin-left: 44px;

        &:hover {
          background-color: $blue600;
          color: $purewhite;
          cursor: pointer;
        }

        .hideAppName {
          @include bodySmall();
          padding: 8px 0;
        }

        .support-icon-col {
          padding-left: 2px;
        }
      }
    }

    .hide {
      .support-request {
        .support-row {
          padding-left: 24px !important;
        }
      }
    }
  }

  .close-menu-row {
    display: flex;
    flex-direction: row;
    padding: 20px 20px 20px 0;

    .close-menu {
      color: $blue500;
    }

    button {
      @include bodySmall();
      padding: 0 0 0 8px;
      background-color: $purewhite !important;
      color: $blue500;
      border: none;
    }

    svg {
      color: $blue500 !important;
    }
  }
}

.top-workspace-nav.hide {
  margin-bottom: 10px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 3px 7px rgba(0, 0, 0, 0.13);
  height: 130px;
  padding-block-start: 70px;

  .app-list {
    display: none;
  }

  .nav-content {
    max-height: 64px;

    .close-menu-row {
      padding-left: 56px;

      .close-menu {
        color: $blackwhite50;
      }

      .list-icon {
        text-align: right;
        padding-right: 4px;
      }

      .menu {
        padding-left: 4px;

        button {
          @include h5();
          padding: 0;
          background-color: $blue400;
          border: none;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .top-workspace-nav.show {
    padding-block-start: 100px;
    min-height: 576px;
    .close-menu-row {
      padding-left: 84px !important;
    }
  }
  .search-col {
    .nav-app-item {
      margin-inline-start: $spacing08 !important;
    }
  }
}

@media (max-width: 768px) {

  .top-workspace-nav.hide {
    .close-menu-row {
      padding-left: 8px !important;
    }
  }


}

@media (max-width: 575px) {
  .top-workspace-nav.hide {
    .close-menu-row {
      padding-left: 92px !important;
    }
  }

  .top-workspace-nav.show {
    .support-icon-col {
      svg {
        margin-right: 8px;
      }
    }
  }
}

@media (max-width: 1399px) {
  .search-col {
    padding-block-start: 7px;
  }
}
